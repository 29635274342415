import React, { Component } from "react";
import { auth, db } from "../services/firebase";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Table from "react-bootstrap/Table";
import SortButton from "../components/SortButton";
import FulltextSearchPanel from "../components/FulltextSearchPanel";
import QRCode from "../components/QRCode";
import ClearFilters from "../components/ClearFilters";
import getSortedValues from "../helpers/getSortedValues";
import filterSameValues from "../helpers/filterSameValues";

export default class Contracts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: auth().currentUser,
            contracts: [],
            readError: null,
            writeError: null,
            loading: true,
            sortBy: "TIMESTAMP",
            sortDirection: "up",
            filterByField: [],
            filterByValue: [],
            fulltextSearchPhrase: "",
        };
        this.operation = [];
        this.prevOperation = [];
        this.interval = undefined;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        this.setState({ readError: null, loading: true });
        try {
            let operationSnapshots = [];
            db.collection("sortiment").onSnapshot((sortimentSnapshot) => {
                sortimentSnapshot.docs.forEach(({ id: sortimentId }) => {
                    if (!operationSnapshots.includes(sortimentId)) {
                        operationSnapshots.push(sortimentId);
                        db.collection("sortiment")
                            .doc(sortimentId)
                            .collection("operation")
                            .onSnapshot((operationSnapshot) => {
                                operationSnapshot.docs.forEach(
                                    (operationDocTmp) => {
                                        const operationDoc = {
                                            ...operationDocTmp.data(),
                                            UID: operationDocTmp.id,
                                            SORTIMENT_UID: sortimentId,
                                        };
                                        const operationExists = this.operation.some(
                                            (v) => v.UID === operationDoc.UID
                                        );
                                        this.operation = operationExists
                                            ? this.operation.map((v) =>
                                                  v.UID === operationDoc.UID
                                                      ? operationDoc
                                                      : v
                                              )
                                            : this.operation.concat(
                                                  operationDoc
                                              );
                                    }
                                );
                            });
                    }
                });
            });
        } catch (error) {
            console.log(error);
            this.setState({ readError: error.message, loading: false });
        }
        this.interval = setInterval(this.deriveContracts.bind(this), 1000);
    }

    async componentWillUnmount() {
        clearInterval(this.interval);
    }

    deriveContracts() {
        const hasAnyOperationChanged =
            this.prevOperation.length !== this.operation.length ||
            this.prevOperation.some((v, i) => v !== this.operation[i]);
        if (hasAnyOperationChanged) {
            this.prevOperation = this.operation.slice();
            const stockInOperationsBySortimentUid = this.operation
                .filter((v) =>
                    ["NASKLADNIT", "NASKLADNIT_ZBYTEK"].includes(v.OPERACE)
                )
                .sort((a, b) => a.TIMESTAMP - b.TIMESTAMP)
                .reduce(
                    (a, v) => ({
                        ...a,
                        [v.SORTIMENT_UID]: (a[v.SORTIMENT_UID] ?? []).concat(v),
                    }),
                    {}
                );
            const stockOutOperations = this.operation
                .filter((operation) => operation.OPERACE === "VYSKLADNIT")
                .sort((a, b) => a.TIMESTAMP - b.TIMESTAMP);
            const { result: rawContracts } = stockOutOperations.reduce(
                ({ result, stockIn }, stockOutItem) => {
                    if (
                        !(
                            Array.isArray(
                                stockIn[stockOutItem.SORTIMENT_UID]
                            ) && stockIn[stockOutItem.SORTIMENT_UID][0]
                        )
                    ) {
                        return { result, stockIn };
                    }
                    const stockInItem = stockIn[stockOutItem.SORTIMENT_UID][0]; // Pick oldest stocked in sortiment item
                    if (stockInItem.KUSU - stockOutItem.KUSU > 0) {
                        stockIn[stockOutItem.SORTIMENT_UID][0].KUSU =
                            stockInItem.KUSU - stockOutItem.KUSU;
                    } else if (stockIn[stockOutItem.SORTIMENT_UID].length > 1) {
                        stockIn[stockOutItem.SORTIMENT_UID].shift();
                    } else {
                        delete stockIn[stockOutItem.SORTIMENT_UID];
                    }
                    return {
                        result: result.concat({
                            ...stockOutItem,
                            OBJEDNAVKA: stockInItem,
                        }),
                        stockIn,
                    };
                },
                {
                    result: [],
                    stockIn: JSON.parse(
                        JSON.stringify(stockInOperationsBySortimentUid)
                    ),
                }
            );
            const contracts = rawContracts.map((v) => ({
                ZAKAZKA: v.ZAKAZKA_LIST ? v.ZAKAZKA_LIST.join() : "Nedefinováno",
                FAKTURA: v.OBJEDNAVKA.FAKTURA ?? "",
                DODACI_LIST: v.OBJEDNAVKA.DODACI_LIST ?? "",
                KUSU: v.KUSU,
                SORTIMENT_UID: v.SORTIMENT_UID,
                OBJEDNAVKA: v.OBJEDNAVKA.OBJEDNAVKA,
                TIMESTAMP: v.TIMESTAMP,
                USER: v.USER,
            }));
            this.setState({ contracts, loading: false });
        }
    }

    handleChange(event) {
        const value = event.target.value;
        this.setState({
            [event.target.name]: value,
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ writeError: null, loading: true });
        try {
            await db.collection("contracts").add({
                id: this.state.id,
                invoices: this.state.invoices,
                contracts: this.state.contracts,
                user: this.state.user?.uid,
                created: Date.now(),
            });
            this.setState({ label: "", loading: false });
        } catch (error) {
            this.setState({ writeError: error.message, loading: false });
        }
    }

    formatTime(timestamp) {
        const d = new Date(timestamp);
        const time = `${d.getDate()}/${
            d.getMonth() + 1
        }/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
        return time;
    }

    clearFilters() {
        this.setState({ filterByField: [], filterByValue: [] });
    }

    render() {
        const sortedValues = getSortedValues(this.state, this.state.contracts);
        return (
            <div>
                <Header />
                <div>
                    {this.state.loading ? (
                        <div
                            className="spinner-border text-success"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    ) : (
                        ""
                    )}

                    <FulltextSearchPanel
                        value={this.state.fulltextSearchPhrase}
                        onChange={(v) => this.setState({ ...v })}
                    />

                    <div className="table-wrapper">
                        <form onSubmit={this.handleSubmit} className="mx-3">
                            <Table>
                                <thead>
                                    <tr>
                                        <SortButton
                                            onChange={(v) =>
                                                this.setState({ ...v })
                                            }
                                            sortDirection={
                                                this.state.sortDirection
                                            }
                                            sortBy={this.state.sortBy}
                                            differentValues={filterSameValues(
                                                this.state.contracts,
                                                "ZAKAZKA"
                                            )}
                                            filterByField={
                                                this.state.filterByField
                                            }
                                            filterByValue={
                                                this.state.filterByValue
                                            }
                                            label="ZAKAZKA"
                                        >
                                            Zakázka
                                        </SortButton>
                                        <th>Faktura</th>
                                        <th>Dodací list</th>
                                        <SortButton
                                            onChange={(v) =>
                                                this.setState({ ...v })
                                            }
                                            sortDirection={
                                                this.state.sortDirection
                                            }
                                            sortBy={this.state.sortBy}
                                            differentValues={filterSameValues(
                                                this.state.contracts,
                                                "KUSU"
                                            )}
                                            filterByField={
                                                this.state.filterByField
                                            }
                                            filterByValue={
                                                this.state.filterByValue
                                            }
                                            label="KUSU"
                                        >
                                            Počet kusů
                                        </SortButton>
                                        <SortButton
                                            onChange={(v) =>
                                                this.setState({ ...v })
                                            }
                                            sortDirection={
                                                this.state.sortDirection
                                            }
                                            sortBy={this.state.sortBy}
                                            differentValues={filterSameValues(
                                                this.state.contracts,
                                                "SORTIMENT_UID"
                                            )}
                                            filterByField={
                                                this.state.filterByField
                                            }
                                            filterByValue={
                                                this.state.filterByValue
                                            }
                                            label="SORTIMENT_UID"
                                        >
                                            Sortiment Identifikátor
                                        </SortButton>
                                        <SortButton
                                            onChange={(v) =>
                                                this.setState({ ...v })
                                            }
                                            sortDirection={
                                                this.state.sortDirection
                                            }
                                            sortBy={this.state.sortBy}
                                            differentValues={filterSameValues(
                                                this.state.contracts,
                                                "OBJEDNAVKA"
                                            )}
                                            filterByField={
                                                this.state.filterByField
                                            }
                                            filterByValue={
                                                this.state.filterByValue
                                            }
                                            label="OBJEDNAVKA"
                                        >
                                            Objednávka
                                        </SortButton>
                                        <SortButton
                                            onChange={(v) =>
                                                this.setState({ ...v })
                                            }
                                            sortDirection={
                                                this.state.sortDirection
                                            }
                                            sortBy={this.state.sortBy}
                                            label="TIMESTAMP"
                                        >
                                            Datum
                                        </SortButton>
                                        <SortButton
                                            onChange={(v) =>
                                                this.setState({ ...v })
                                            }
                                            sortDirection={
                                                this.state.sortDirection
                                            }
                                            sortBy={this.state.sortBy}
                                            label="USER"
                                        >
                                            Uživatel
                                        </SortButton>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedValues.map((contract) => {
                                        return (
                                            <tr
                                                key={`${contract["ZAKAZKA"]}.${contract["SORTIMENT_UID"]}.${contract["TIMESTAMP"]}`}
                                            >
                                                <td>{contract["ZAKAZKA"]}</td>
                                                <td>{contract["FAKTURA"]}</td>
                                                <td>
                                                    {contract["DODACI_LIST"]}
                                                </td>
                                                <td>{contract["KUSU"]}</td>
                                                <td>
                                                    {contract["SORTIMENT_UID"]}
                                                </td>
                                                <td>
                                                    {contract["OBJEDNAVKA"]}
                                                </td>
                                                <td>
                                                    {new Date(
                                                        contract["TIMESTAMP"]
                                                    ).toLocaleString()}
                                                </td>
                                                <td>{contract["USER"]}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </form>
                    </div>
                    {this.state.writeError ? (
                        <p className="text-danger">{this.state.writeError}</p>
                    ) : null}
                </div>
                <ClearFilters onClick={() => this.clearFilters()} />
                <Footer />
            </div>
        );
    }
}
