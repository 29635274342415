import React, { useMemo, useState, useEffect } from "react";
import Select from "react-select";

const SortButton = (props) => {
	const [filter, setFilter] = useState([]);
	const sort = props.label === props.sortBy ? props.sortDirection : "";

	const cachedValues = useMemo(() => (props.differentValues ?? []).slice(), [
		props.differentValues,
	]);

	const handleOnClick = () => {
		props.onChange({
			sortBy: sort !== "up" ? props.label : "",
			sortDirection: sort === "down" ? "up" : sort === "up" ? "" : "down",
		});
	};

	const indexOfFilter =
		props.filterByField && props.filterByField.indexOf(props.label);

	useEffect(() => {
		if (indexOfFilter < 0) {
			setFilter([]);
		}
	}, [props.filterByValue]);

	const handleOnChange = (value) => {
		setFilter(value);
		setTimeout(() => {
			if (indexOfFilter > -1 && value.length < 1) {
				props.onChange({
					filterByField: props.filterByField.filter(
						(_v, i) => i !== indexOfFilter
					),
					filterByValue: props.filterByValue.filter(
						(_v, i) => i !== indexOfFilter
					),
				});
			} else if (indexOfFilter > -1 && value.length > 0) {
				props.onChange({
					filterByField: props.filterByField,
					filterByValue: props.filterByValue.map((v, i) =>
						i === indexOfFilter ? value.map((v) => v.value) : v
					),
				});
			} else {
				props.onChange({
					filterByField: props.filterByField.concat(props.label),
					filterByValue: props.filterByValue.concat([
						value.map((v) => v.value),
					]),
				});
			}
		}, 0);
	};
	return (
		<th className="sort-button-column">
			{props.differentValues && (
				<Select
					onChange={handleOnChange}
					value={filter}
					isMulti
					options={cachedValues.map((v) => ({
						value: v.toString(),
						label: v.toString(),
					}))}
					placeholder="-- Nezáleží --"
					className="basic-multi-select"
				/>
			)}
			<div onClick={handleOnClick}>
				{props.children}
				{sort === "up" ? " ⏫" : sort === "down" ? " ⏬" : ""}
			</div>
		</th>
	);
};

export default SortButton;
