import React, { Component } from "react";
import { auth, db } from "../services/firebase";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Table from "react-bootstrap/Table";
import EditableColumn from "../components/EditableColumn";

export default class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: auth().currentUser,
            userName: null,
            userEmail: null,
            userPassword: null,
            userRole: 'Administrátor',
            readError: null,
            writeError: null,
            users: [],
            loading: true,
        };
    }

    async componentDidMount() {
        this.setState({ readError: null, loading: true });
        try {
            db.collection("user").onSnapshot((querySnapshot) => {
                const users = querySnapshot.docs.map((doc) => {
                    const data = doc.data();
                    return { ...data, uid: doc.id };
                });
                console.log(users);
                this.setState({
                    users,
                    loading: false,
                });
            });
        } catch (error) {
            this.setState({ readError: error.message, loading: false });
        }
    }

    handleRemoveUser(uid) {
        db.collection("user")
            .doc(uid)
            .delete();
    }

    handleFieldChange(fieldName, uid, value) {
        db.collection("user")
            .doc(uid)
            .update({
                [fieldName]: value,
            });
    }

    handleChange(event) {
        const value = event.target.value;
        this.setState({
            [event.target.name]: value,
        });
    }

    async handleSubmit() {
        if (this.state.userPassword.length < 6) {
            alert('Heslo musí být alespoň 6 znaků dlouhé.');
            return;
        }

        this.setState({ writeError: null, loading: true });
        try {
            await db.collection("user").add({
                USER_NAME: this.state.userName,
                USER_PASSWORD: this.state.userPassword,
                USER_ROLE: this.state.userRole,
            });
            this.setState({ loading: false });
        } catch (error) {
            this.setState({ writeError: error.message, loading: false });
        }
    }

    render() {
        return (
            <div>
                <Header />
                <div>
                    {this.state.loading ? (
                        <div
                            className="spinner-border text-success"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="table-wrapper">
                        <Table>
                            <thead>
                                <tr>
                                    <th>Jméno</th>
                                    <th>Heslo</th>
                                    <th>Role</th>
                                    <th>Akce</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.users.map((user) => (
                                    <tr key={user.uid}>
                                        <td>{user.USER_NAME}</td>
                                        <td>
                                            <EditableColumn
                                                title="Heslo"
                                                value={user.USER_PASSWORD}
                                                onChange={(v) =>
                                                    this.handleFieldChange(
                                                        "USER_PASSWORD",
                                                        user.uid,
                                                        v
                                                    )
                                                }
                                            />
                                        </td>
                                        <td>{user.USER_ROLE}</td>
                                        <td>
                                            <button
                                                type="button"
                                                id="remove"
                                                name={user.uid}
                                                className="btn btn-danger"
                                                onClick={() =>
                                                    this.handleRemoveUser(
                                                        user.uid
                                                    )
                                                }
                                            >
                                                X
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td>
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                name="userName"
                                                className="form-control"
                                                placeholder="Zadejte jméno"
                                                onChange={(e) =>
                                                    this.handleChange(e)
                                                }
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                name="userPassword"
                                                className="form-control"
                                                placeholder="Zadejte heslo"
                                                onChange={(e) =>
                                                    this.handleChange(e)
                                                }
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-group mb-3">
                                            <select
                                                name="userRole"
                                                className="custom-select"
                                                onChange={(e) =>
                                                    this.handleChange(e)
                                                }
                                            >
                                                <option>Administrátor</option>
                                                <option>Programátor</option>
                                                <option>Nákupčí</option>
                                                <option>Skladník</option>
                                            </select>
                                        </div>
                                    </td>

                                    <td>
                                        <button
                                            onClick={() => this.handleSubmit()}
                                            className="btn btn-submit px-5"
                                        >
                                            Přidat
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    {this.state.writeError ? (
                        <p className="text-danger">{this.state.writeError}</p>
                    ) : null}
                </div>

                <Footer />
            </div>
        );
    }
}
