import React, {Component} from "react";
import {auth, db} from "../services/firebase";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default class GenQRCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: auth().currentUser,
            readError: null,
            writeError: null,
            users: [],
            loading: true
        };
    }
    
    render() {
        return (
            <div>
                <Header/>
                <div>
                    {this.state.loading ?
                        <div className="spinner-border text-success" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        : ""
                    }
                    Vygeneruj QR kód
                    {this.state.writeError ? <p className="text-danger">{this.state.writeError}</p> : null}
                </div>

                <Footer/>
            </div>
        )
    }
}
