import React from 'react';
import { useQRCode } from 'react-hook-qrcode';


function QRCode(props) {
    const [inputRef] = useQRCode({
        text: props.input,
        options: {
            level: 'M',
            margin: 7,
            scale: 1,
            width: 200,
            color: {
                dark: '#000000FF',
                light: '#FFFFFFFF',
            },
        },
    });

    return <canvas ref={inputRef} />;
}

export default QRCode;