import React from 'react';

function Footer() {
  return (
    <footer id="footer">
      <div className="text-center">
        <p>&copy; cexbit.com 2020 Verze: 21 <a href="com.cexbit.qragenda-21.apk">Stahni android apku</a></p>
      </div>
    </footer>
  )
}

export default Footer;