import React from "react";

const ClearFilter = (props) => {
	return (
		<button
			type="button"
			className="btn clearFiltersFAB btn-danger pmd-ripple-effect pmd-btn-flat"
			onClick={props.onClick}
		>
			Zrušit filtry
		</button>
	);
};

export default ClearFilter;
