import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import { auth, db } from "./services/firebase";
import AuthContext from "./helpers/AuthContext";
import Home from "./pages/Home";
import SortimentType from "./pages/SortimentType";
import SortimentLocation from "./pages/SortimentLocation";
import Location from "./pages/Location";
import Sortiment from "./pages/Sortiment";
import Orders from "./pages/Orders";
import Contracts from "./pages/Contracts";
import GenQRCode from "./pages/GenQRCode";
import Users from "./pages/Users";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import "./styles.css";

function PrivateRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

function PublicRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === false ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}

export default function(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasAuthenticated, setHasAuthenticated] = useState(false);
  const [username, setUsername] = useState();
  const [userId, setUserId] = useState();
  const [userRole, setUserRole] = useState();
  const handleAuth = useRef();
  const handleLogout = useRef();

  useLayoutEffect(() => {
    handleAuth.current = async (username, password) => {
      try {
        await auth().signInWithEmailAndPassword(
          `${username}@cexbit.com`,
          password
        );
        const USER_UID = auth().currentUser.uid;
        const snapshot = await db
          .collection("user")
          .doc(USER_UID)
          .get();
        const { USER_NAME, USER_ROLE } = snapshot.data();
        setUsername(USER_NAME);
        setUserId(USER_UID);
        setUserRole(USER_ROLE);
        setHasAuthenticated(true);
        setIsLoaded(true);
        window.localStorage.setItem("user", [username, password].join(";"));
      } catch (e) {
        setHasAuthenticated(false)
        setIsLoaded(true)
        console.warn("Username or password was wrong.");
      }
    };
    handleLogout.current = () => {
      auth().signOut();
      setHasAuthenticated(false);
      setUsername();
      setUserRole();
      setUserId();
      window.localStorage.removeItem('user');
    };
  }, [hasAuthenticated, username, userId]);

  useEffect(() => {
    if (window.localStorage.getItem("user")) {
      handleAuth.current(...window.localStorage.getItem("user").split(";"));
    } else {
      setIsLoaded(true);
    }
  }, []);

  return !isLoaded ? (
    <div className="spinner-border text-success" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  ) : (
    <AuthContext.Provider
      value={{
        logout: handleLogout,
        username,
        userRole,
        hasAuthenticated,
      }}
    >
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <PrivateRoute
            path="/sortiment_type"
            authenticated={hasAuthenticated && userRole === "Administrátor"}
            component={SortimentType}
          />
          <PrivateRoute
            path="/location"
            authenticated={hasAuthenticated && userRole === "Administrátor"}
            component={Location}
          />
          <PrivateRoute
            path="/sortiment_location"
            authenticated={hasAuthenticated && userRole === "Administrátor"}
            component={SortimentLocation}
          />
          <PrivateRoute
            path="/sortiment"
            authenticated={hasAuthenticated}
            component={Sortiment}
          />
          <PrivateRoute
            path="/orders"
            authenticated={
              hasAuthenticated &&
              ["Administrátor", "Programátor", "Nákupčí"].includes(userRole)
            }
            component={Orders}
          />
          <PrivateRoute
            path="/contracts"
            authenticated={
              hasAuthenticated &&
              ["Administrátor", "Nákupčí"].includes(userRole)
            }
            component={Contracts}
          />

          <PrivateRoute
            path="/users"
            authenticated={hasAuthenticated && userRole === "Administrátor"}
            component={Users}
          />
          <PrivateRoute
            path="/genqrcode"
            authenticated={
              hasAuthenticated &&
              ["Administrátor", "Skladník"].includes(userRole)
            }
            component={GenQRCode}
          />
          <PublicRoute
            path="/signup"
            authenticated={hasAuthenticated}
            component={Signup}
          />
          <PublicRoute
            path="/login"
            authenticated={hasAuthenticated}
            handleAuth={handleAuth}
            component={Login}
          />
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
}
