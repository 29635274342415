import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SortButton from "../components/SortButton";
import FulltextSearchPanel from "../components/FulltextSearchPanel";
import Table from "react-bootstrap/Table";
import ClearFilters from "../components/ClearFilters";
import { db } from "../services/firebase";
import getSortedValues from '../helpers/getSortedValues';
import filterSameValues from '../helpers/filterSameValues';

export default function Location(props) {
	const [isLoading, setIsLoading] = useState(false);
	const [locations, setLocations] = useState([]);
	const [sortBy, setSortBy] = useState("");
	const [sortDirection, setSortDirection] = useState("");
	const [filterByField, setFilterByField] = useState([]);
	const [filterByValue, setFilterByValue] = useState([]);
	const [fulltextSearchPhrase, setFulltextSearchPhrase] = useState("");

	useEffect(() => {
		db.collection("sortiment").onSnapshot((querySnapshot) => {
			const values = querySnapshot.docs.flatMap((doc) => {
				const obj = doc.data();
				return Object.keys(obj)
					.filter((key) => key.startsWith("LOKACE-"))
					.map((key) => {
						return {
							sortiment: doc.id,
							location: key.slice(7),
							amount: obj[key],
						};
					});
			});
			setLocations(values);
			setIsLoading(false);
		});
	}, []);

	const sortedValues = getSortedValues({
		sortBy, sortDirection, filterByField, filterByValue, fulltextSearchPhrase,
	}, locations);

	return (
		<>
			<Header />
			<div>
				<div>
					{isLoading && (
						<div
							className="spinner-border text-success"
							role="status"
						>
							<span className="sr-only">Loading...</span>
						</div>
					)}
				</div>

				<FulltextSearchPanel
					value={fulltextSearchPhrase}
					onChange={(v) =>
						setFulltextSearchPhrase(v.fulltextSearchPhrase)
					}
				/>

				<div className="table-wrapper">
					<Table>
						<thead>
							<tr>
								<SortButton
									onChange={(v) => {
										if ("sortDirection" in v) {
											setSortDirection(v.sortDirection);
											setSortBy(v.sortBy);
										} else {
											setFilterByField(v.filterByField);
											setFilterByValue(v.filterByValue);
										}
									}}
									sortDirection={sortDirection}
									sortBy={sortBy}
									differentValues={filterSameValues(locations, "sortiment")}
									filterByField={filterByField}
									filterByValue={filterByValue}
									label="sortiment"
								>
									Sortiment
								</SortButton>
								<SortButton
									onChange={(v) => {
										if ("sortDirection" in v) {
											setSortDirection(v.sortDirection);
											setSortBy(v.sortBy);
										} else {
											setFilterByField(v.filterByField);
											setFilterByValue(v.filterByValue);
										}
									}}
									sortDirection={sortDirection}
									sortBy={sortBy}
									differentValues={filterSameValues(locations, "location")}
									filterByField={filterByField}
									filterByValue={filterByValue}
									label="location"
								>
									Lokace
								</SortButton>
								<SortButton
									onChange={(v) => {
										setSortDirection(v.sortDirection);
										setSortBy(v.sortBy);
									}}
									sortDirection={sortDirection}
									sortBy={sortBy}
									label="amount"
								>
									Počet
								</SortButton>
							</tr>
						</thead>
						<tbody>
							{sortedValues.map((value) => (
								<tr
									key={`${value.sortiment}.${value.location}`}
								>
									<td>{value.sortiment}</td>
									<td>{value.location}</td>
									<td>{value.amount}</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
			</div>
            <ClearFilters onClick={() => {
            	setFilterByField([]);
            	setFilterByValue([]);
            }} />
			<Footer />
		</>
	);
}
