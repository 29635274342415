import React from "react";

const Modal = (props) => {
  return (
    <div className="modal show" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.7)" }}>
      <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Operace
            </h5>

            <button
              type="button"
              className="close"
              onClick={props.onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ maxHeight: "50vh", overflow: "scroll" }}>{props.children}</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={props.onClose}
            >
              Zavřít
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
