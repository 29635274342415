import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      username: "",
      password: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ error: "" });
    try {
      await this.props.handleAuth.current(
        this.state.username,
        this.state.password
      );
    } catch (error) {
      this.setState({ error: error.message });
    }
  }

  render() {
    return (
      <>
        <Header />
        <div className="container">
          <form
            className="mt-5 py-5 px-5"
            autoComplete="off"
            onSubmit={this.handleSubmit}
          >
            <h1>
              Přihlásit se do
              <Link className="title ml-2" to="/">
                Skladové evidence
              </Link>
            </h1>
            <p className="lead">Vyplňte následující údaje</p>
            <div className="form-group">
              <input
                className="form-control"
                placeholder="Username"
                name="username"
                type="text"
                onChange={this.handleChange}
                value={this.state.username}
              />
            </div>
            <div className="form-group">
              <input
                className="form-control"
                placeholder="Heslo"
                name="password"
                onChange={this.handleChange}
                value={this.state.password}
                type="password"
              />
            </div>
            <div className="form-group">
              {this.state.error ? (
                <p className="text-danger">{this.state.error}</p>
              ) : null}
              <button className="btn btn-primary px-5" type="submit">
                Přihlásit
              </button>
            </div>
          </form>
        </div>
        <Footer />
      </>
    );
  }
}
