import React from "react";

const FilterButton = (props) => {
	return (
		<div className="head-column mr-2">
			<label>{props.label}</label>
			<select
				name={props.name}
				className="custom-select"
				onChange={props.onChange}
			>
				<option disabled selected>
					Vyberte
				</option>
				{(props.options ?? []).map((v) => (
					<option key={v}>{v}</option>
				))}
			</select>
		</div>
	);
};

export default FilterButton;
