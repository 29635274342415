import React, { Component } from "react";
import { auth, db } from "../services/firebase";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Table from "react-bootstrap/Table";

export default class SortimentType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: auth().currentUser,
            sortimentTypeNum: null,
            sortimentTypeCategory: null,
            sortimentTypeLabel: null,
            sortimentTypeIndex: null,
            sortimentTypeParent: null,
            sortimentTypeValues: [],
            readError: null,
            writeError: null,
            loading: true,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        this.setState({ readError: null, loading: true });
        try {
            db.collection("sortiment_type").onSnapshot((querySnapshot) => {
                let values = querySnapshot.docs
                    .sort((a, b) => {
                        return a.data()["num"] - b.data()["num"];
                    })
                    .map((doc) => ({ ...doc.data(), uid: doc.id }));
                // console.log(values);
                this.setState({
                    sortimentTypeValues: values,
                });
                this.setState({ loading: false });
            });
        } catch (error) {
            this.setState({ readError: error.message, loading: false });
        }
    }

    /*
        writeSortimentType(label, index) {
            db.ref('/sortiment_type').set({
                label: label,
                index: index,
            }).then()
        }
    */

    handleRemoveSortimentType(uid, name) {
        if (window.confirm(`Jste si jisti, že chcete odstranit kategorii sortimentu ${name} ?`)) {
            db.collection("sortiment_type").doc(uid).delete();
        }
    }

    handleChange(event) {
        const value = event.target.value;
        this.setState({
            [event.target.name]: value,
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ writeError: null, loading: true });
        try {
            await db.collection("sortiment_type").add({
                num: this.state.sortimentTypeNum,
                category: this.state.sortimentTypeCategory ?? "TypDílu",
                label: this.state.sortimentTypeLabel,
                index: this.state.sortimentTypeIndex,
                parent: this.state.sortimentTypeParent ?? "",
                timestamp: Date.now(),
                user: this.state.user?.uid,
            });
            this.setState({ label: "", loading: false });
        } catch (error) {
            this.setState({ writeError: error.message, loading: false });
        }
    }

    formatTime(timestamp) {
        const d = new Date(timestamp);
        const time = `${d.getDate()}/${
            d.getMonth() + 1
        }/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
        return time;
    }

    render() {
        return (
            <div>
                <Header />
                <div className="table-wrapper">
                    {this.state.loading ? (
                        <div
                            className="spinner-border text-success"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    ) : (
                        ""
                    )}

                    <form onSubmit={this.handleSubmit} className="mx-3">
                        <Table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Kategorie</th>
                                    <th>Název</th>
                                    <th>Index</th>
                                    <th>Extras</th>
                                    <th>Akce</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.sortimentTypeValues.map((value) => (
                                    <tr key={value.uid}>
                                        <td>{value.num}</td>
                                        <td>{value.category}</td>
                                        <td>{value.label}</td>
                                        <td>{value.index}</td>
                                        <td>{value.parent}</td>
                                        <td>
                                            <button
                                                type="button"
                                                id="remove"
                                                name={value.uid}
                                                className="btn btn-danger"
                                                onClick={() =>
                                                    this.handleRemoveSortimentType(
                                                        value.uid,
                                                        value.label,
                                                    )
                                                }
                                            >
                                                X
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td>
                                        <input
                                            type="text"
                                            name="sortimentTypeNum"
                                            placeholder="#"
                                            onChange={this.handleChange}
                                        />
                                    </td>
                                    <td>
                                        <select
                                            name="sortimentTypeCategory"
                                            onChange={this.handleChange}
                                        >
                                            <option selected>TypDílu</option>
                                            <option>Materiál</option>
                                            <option>Třída</option>
                                            <option>Povrch</option>
                                            <option>Děrování</option>
                                            <option>SílaPlechu</option>
                                            <option>Rozměr</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name="sortimentTypeLabel"
                                            placeholder="vyplnit název"
                                            onChange={this.handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name="sortimentTypeIndex"
                                            placeholder="vyplnit index"
                                            onChange={this.handleChange}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name="sortimentTypeParent"
                                            placeholder="parent"
                                            onChange={this.handleChange}
                                        />
                                    </td>
                                    <td>
                                        <button
                                            type="submit"
                                            className="btn btn-submit px-5 mt-4"
                                        >
                                            Přidat nový
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </form>

                    {this.state.writeError ? (
                        <p className="text-danger">{this.state.writeError}</p>
                    ) : null}
                </div>

                <Footer />
            </div>
        );
    }
}
