import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyDOFL4V2APVqqsThO8Wml9NutBP971vVds",
  authDomain: "qragenda-0001.firebaseapp.com",
  databaseURL: "https://qragenda-0001.firebaseio.com",
  projectId: "qragenda-0001",
  storageBucket: "qragenda-0001.appspot.com",
  messagingSenderId: "525738126698",
  appId: "1:525738126698:web:19625a569ccfb109154913",
  measurementId: "G-CJKGH475KZ"
};

firebase.initializeApp(config);

export const auth = firebase.auth;
export const db = firebase.firestore();
