import React, { Component } from "react";
import { auth, db } from "../services/firebase";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SortButton from "../components/SortButton";
import FilterButton from "../components/FilterButton";
import FulltextSearchPanel from "../components/FulltextSearchPanel";
import Table from "react-bootstrap/Table";
import EditableColumn from "../components/EditableColumn";
import AuthContext from "../helpers/AuthContext";
import QRCode from "../components/QRCode";
import ClearFilters from "../components/ClearFilters";
import Modal from "../components/Modal";
import getSortedValues, { getFilteredValues } from "../helpers/getSortedValues";
import filterSameValues from "../helpers/filterSameValues";

async function submitBlockRequest(uid, operation) {
    let doc = await db.collection("sortiment").doc(uid).get();
    //console.log(doc);
    let count = isNaN(doc.data()["BLOKOVANO"]) ? 0 : doc.data()["BLOKOVANO"];
    console.log(count);
    if (operation === "plus") {
        await db
            .collection("sortiment")
            .doc(uid)
            .update({
                BLOKOVANO: count + 1,
            });
    }
    if (operation === "minus") {
        if (count > 0) {
            await db
                .collection("sortiment")
                .doc(uid)
                .update({
                    BLOKOVANO: count - 1,
                });
        }
    }
    if (operation === "vynulovat") {
        if (count > 0) {
            await db
                .collection("sortiment")
                .doc(uid)
                .update({ BLOKOVANO: 0 });
        }
    }
    return null;
}

export default class Sortiment extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            user: auth().currentUser,
            readError: null,
            writeError: null,
            sortimentValues: [],
            operations: null,
            loading: true,
            sortBy: "",
            sortDirection: "",
            filterByField: [],
            filterByValue: [],
            fulltextSearchPhrase: "",
            lists: {},
            newSortiment: {},
            showOperationModalForUid: null,
            hideZeroItems: true,
        };
    }

    async componentDidMount() {
        this.setState({ readError: null, loading: true });
        try {
            db.collection("sortiment").onSnapshot((snapshot) => {
                let data = [];
                snapshot.forEach((doc) => {
                    data.push({ uid: doc.id, ...doc.data() });
                });
                this.setState({ loading: false, sortimentValues: data });
            });
            db.collection("sortiment_type").onSnapshot((snapshot) => {
                let data = [];
                snapshot.forEach((doc) => {
                    data.push({ uid: doc.id, ...doc.data() });
                });
                const lists = data
                    .sort((a, b) => Number.parseInt(a.index) - Number.parseInt(b.index))
                    .reduce(
                        (aLists, { category, label }) => ({
                            ...aLists,
                            [category]: (aLists[category] ?? []).concat(
                                !(aLists[category] ?? []).includes(label)
                                    ? label
                                    : []
                            ),
                        }),
                        {}
                    );
                this.setState({ lists });
            });
        } catch (error) {
            this.setState({ readError: error.message, loading: false });
        }
    }

    async componentDidUpdate(_prevProps, prevState) {
        if (
            prevState.showOperationModalForUid !==
                this.state.showOperationModalForUid &&
            this.state.showOperationModalForUid
        ) {
            db.collection("sortiment")
                .doc(this.state.showOperationModalForUid)
                .collection("operation")
                .onSnapshot((snapshot) => {
                    const operations = snapshot.docs
                        .map((doc) => {
                            const data = doc.data();
                            return { ...data, uid: doc.id };
                        })
                        .sort((a, b) => a.TIMESTAMP - b.TIMESTAMP);
                    this.setState({ operations });
                });
        }
    }

    handleChange(e) {
        if (e.target.value === "Neuvedeno") {
            const newSortiment = Object.fromEntries(
                Object.entries(this.state.newSortiment).filter(
                    ([key]) => key !== e.target.name
                )
            );
            this.setState({ newSortiment });
            return;
        }
        this.setState({
            newSortiment: {
                ...this.state.newSortiment,
                [e.target.name]: e.target.value,
            },
        });
    }

    async handleSubmit() {
        this.setState({ writeError: null, loading: true });
        try {
            const {
                TYP_DILU,
                MATERIAL,
                TRIDA,
                POVRCH,
                DEROVANI,
                SILA_PLECHU,
                ROZMER,
            } = this.state.newSortiment;
            const derovaniVyraz = DEROVANI ? `${DEROVANI}-` : "";
            const id = `SORTIMENT-${TYP_DILU}-${MATERIAL}-${TRIDA}-${POVRCH}-${derovaniVyraz}${SILA_PLECHU}-${ROZMER}`;
            await db
                .collection("sortiment")
                .doc(id)
                .set({ id, ...this.state.newSortiment }, { merge: true });
            this.setState({ loading: false });
        } catch (error) {
            this.setState({ writeError: error.message, loading: false });
        }
    }

    async handleFieldChange(fieldName, uid, value) {
        db.collection("sortiment")
            .doc(uid)
            .update({
                [fieldName]: value,
            });
    }

    clearFilters() {
        this.setState({ filterByField: [], filterByValue: [] });
    }

    showOperationModal(uid) {
        this.setState({ showOperationModalForUid: uid });
    }

    hideOperationModal() {
        this.setState({ showOperationModalForUid: null, operations: null });
    }

    render() {
        const sortedValues = getSortedValues(
            this.state,
            this.state.hideZeroItems
                ? this.state.sortimentValues.filter((v) => v["SKLADEM"] > 0)
                : this.state.sortimentValues
        );
        return (
            <div>
                <Header />
                <div>
                    {this.state.loading ? (
                        <div
                            className="spinner-border text-success"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    ) : (
                        ""
                    )}

                    {["Administrátor", "Programátor"].includes(
                        this.context.userRole
                    ) && (
                        <div className="form-row-wrapper">
                            <FilterButton
                                label="Typ dílu"
                                name="TYP_DILU"
                                onChange={(e) => this.handleChange(e)}
                                options={this.state.lists["TypDílu"]}
                            />
                            <FilterButton
                                label="Materiál:"
                                name="MATERIAL"
                                onChange={(e) => this.handleChange(e)}
                                options={this.state.lists["Materiál"]}
                            />
                            <FilterButton
                                label="Třída:"
                                name="TRIDA"
                                onChange={(e) => this.handleChange(e)}
                                options={this.state.lists["Třída"]}
                            />
                            <FilterButton
                                label="Povrch:"
                                name="POVRCH"
                                onChange={(e) => this.handleChange(e)}
                                options={this.state.lists["Povrch"]}
                            />
                            <FilterButton
                                label="Děrování:"
                                name="DEROVANI"
                                onChange={(e) => this.handleChange(e)}
                                options={this.state.lists["Děrování"]}
                            />
                            <FilterButton
                                label="Síla plechu:"
                                name="SILA_PLECHU"
                                onChange={(e) => this.handleChange(e)}
                                options={this.state.lists["SílaPlechu"]}
                            />
                            <FilterButton
                                label="Rozměr:"
                                name="ROZMER"
                                onChange={(e) => this.handleChange(e)}
                                options={this.state.lists["Rozměr"]}
                            />
                            <div>
                                <button
                                    onClick={() => this.handleSubmit()}
                                    className="btn btn-submit"
                                    disabled={
                                        ![
                                            "TYP_DILU",
                                            "MATERIAL",
                                            "TRIDA",
                                            "POVRCH",
                                            "SILA_PLECHU",
                                            "ROZMER",
                                        ].every((v) =>
                                            Object.keys(
                                                this.state.newSortiment
                                            ).includes(v)
                                        )
                                    }
                                >
                                    Přidat
                                </button>
                            </div>
                        </div>
                    )}

                    <FulltextSearchPanel
                        value={this.state.fulltextSearchPhrase}
                        onChange={(v) => this.setState({ ...v })}
                    />

                    <div className="form-check tool-bar">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            checked={this.state.hideZeroItems}
                            onClick={() =>
                                this.setState({
                                    hideZeroItems: !this.state.hideZeroItems,
                                })
                            }
                            id="hideZeroItems"
                        />
                        <label className="form-check-label" htmlFor="hideZeroItems">
                            Skrýt sortiment, který není skladem
                        </label>
                    </div>

                    <div className="table-wrapper">
                        <Table>
                            <thead>
                                <tr>
                                    <th className="head-column">
                                        Identifikátor Sortimentu
                                    </th>

                                    <SortButton
                                        onChange={
                                            (v) => this.setState({ ...v })
                                        }
                                        sortDirection={this.state.sortDirection}
                                        sortBy={this.state.sortBy}
                                        differentValues={this.state.lists["TypDílu"]}
                                        filterByField={this.state.filterByField}
                                        filterByValue={this.state.filterByValue}
                                        label="TYP_DILU"
                                    >
                                        Typ dílu
                                    </SortButton>
                                    <SortButton
                                        onChange={(v) =>
                                            this.setState({ ...v })
                                        }
                                        sortDirection={this.state.sortDirection}
                                        sortBy={this.state.sortBy}
                                        differentValues={this.state.lists["Materiál"]}
                                        filterByField={this.state.filterByField}
                                        filterByValue={this.state.filterByValue}
                                        label="MATERIAL"
                                    >
                                        Materiál
                                    </SortButton>
                                    <SortButton
                                        onChange={(v) =>
                                            this.setState({ ...v })
                                        }
                                        sortDirection={this.state.sortDirection}
                                        sortBy={this.state.sortBy}
                                        differentValues={this.state.lists["Třída"]}
                                        filterByField={this.state.filterByField}
                                        filterByValue={this.state.filterByValue}
                                        label="TRIDA"
                                    >
                                        Třída
                                    </SortButton>
                                    <SortButton
                                        onChange={(v) =>
                                            this.setState({ ...v })
                                        }
                                        sortDirection={this.state.sortDirection}
                                        sortBy={this.state.sortBy}
                                        differentValues={this.state.lists["Povrch"]}
                                        filterByField={this.state.filterByField}
                                        filterByValue={this.state.filterByValue}
                                        label="POVRCH"
                                    >
                                        Povrch
                                    </SortButton>
                                    <SortButton
                                        onChange={(v) =>
                                            this.setState({ ...v })
                                        }
                                        sortDirection={this.state.sortDirection}
                                        sortBy={this.state.sortBy}
                                        differentValues={this.state.lists["Děrování"]}
                                        filterByField={this.state.filterByField}
                                        filterByValue={this.state.filterByValue}
                                        label="DEROVANI"
                                    >
                                        Děrování
                                    </SortButton>
                                    <SortButton
                                        onChange={(v) =>
                                            this.setState({ ...v })
                                        }
                                        sortDirection={this.state.sortDirection}
                                        sortBy={this.state.sortBy}
                                        differentValues={this.state.lists["SílaPlechu"]}
                                        filterByField={this.state.filterByField}
                                        filterByValue={this.state.filterByValue}
                                        label="SILA_PLECHU"
                                    >
                                        Síla plechu
                                    </SortButton>
                                    <SortButton
                                        onChange={(v) =>
                                            this.setState({ ...v })
                                        }
                                        sortDirection={this.state.sortDirection}
                                        sortBy={this.state.sortBy}
                                        differentValues={this.state.lists["Rozměr"]}
                                        filterByField={this.state.filterByField}
                                        filterByValue={this.state.filterByValue}
                                        label="ROZMER"
                                    >
                                        Rozměr
                                    </SortButton>

                                    <th className="lokace-column">
                                        Skladem
                                    </th>
                                    <th className="lokace-column">Lokace</th>
                                    <th>
                                        Blokováno
                                    </th>

                                    {["Administrátor", "Programátor"].includes(
                                        this.context.userRole
                                    ) && <th className="action-column akce-column">Akce</th>}
                                    <th className="qrcode-column">QRCode</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedValues.map((value) => (
                                    <tr key={value.uid}>
                                        <td>{value.uid}</td>
                                        <td>{value["TYP_DILU"]}</td>
                                        <td>{value["MATERIAL"]}</td>
                                        <td>{value["TRIDA"]}</td>
                                        <td>{value["POVRCH"]}</td>
                                        <td>{value["DEROVANI"]}</td>
                                        <td>{value["SILA_PLECHU"]}</td>
                                        <td>{value["ROZMER"]}</td>
                                        <td>
                                            {this.context.userRole ===
                                            "Administrátor" ? (
                                                <EditableColumn
                                                    title="SKLADEM"
                                                    value={value["SKLADEM"]}
                                                    onChange={(v) =>
                                                        this.handleFieldChange(
                                                            "SKLADEM",
                                                            value["uid"],
                                                            Number.parseInt(
                                                                v,
                                                                10
                                                            )
                                                        )
                                                    }
                                                />
                                            ) : (
                                                value["SKLADEM"]
                                            )}
                                        </td>
                                        <td>
                                            <div>
                                                <Table>
                                                    <tbody>
                                                        {Object.entries(value)
                                                            .filter(
                                                                ([
                                                                    fieldName,
                                                                    fieldValue,
                                                                ]) =>
                                                                    this.context
                                                                        .userRole ===
                                                                        "Administrátor" ||
                                                                    fieldValue >
                                                                        0
                                                            )
                                                            .flatMap(
                                                                ([
                                                                    fieldName,
                                                                    fieldValue,
                                                                ]) => {
                                                                    if (
                                                                        fieldName.startsWith(
                                                                            "LOKACE-"
                                                                        )
                                                                    ) {
                                                                        const location = fieldName.slice(
                                                                            7
                                                                        );
                                                                        return [
                                                                            <tr
                                                                                key={
                                                                                    location
                                                                                }
                                                                            >
                                                                                <td>
                                                                                    {
                                                                                        location
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    {this
                                                                                        .context
                                                                                        .userRole ===
                                                                                    "Administrátor" ? (
                                                                                        <EditableColumn
                                                                                            title={
                                                                                                location
                                                                                            }
                                                                                            value={
                                                                                                fieldValue
                                                                                            }
                                                                                            onChange={(
                                                                                                v
                                                                                            ) =>
                                                                                                this.handleFieldChange(
                                                                                                    fieldName,
                                                                                                    value[
                                                                                                        "uid"
                                                                                                    ],
                                                                                                    Number.parseInt(
                                                                                                        v,
                                                                                                        10
                                                                                                    )
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    ) : (
                                                                                        fieldValue
                                                                                    )}
                                                                                </td>
                                                                            </tr>,
                                                                        ];
                                                                    }
                                                                    return [];
                                                                }
                                                            )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </td>
                                        <td>{value["BLOKOVANO"]} </td>
                                        {[
                                            "Administrátor",
                                            "Programátor",
                                        ].includes(this.context.userRole) ? (
                                            <td>
                                                <button
                                                    type="button"
                                                    id="plus"
                                                    name={value.uid}
                                                    className="btn btn-submit ml-1"
                                                    onClick={() =>
                                                        submitBlockRequest(
                                                            value.uid,
                                                            "plus"
                                                        )
                                                    }
                                                >
                                                    +
                                                </button>
                                                <button
                                                    type="button"
                                                    id="minus"
                                                    name={value.uid}
                                                    className="btn btn-submit ml-1"
                                                    onClick={() =>
                                                        submitBlockRequest(
                                                            value.uid,
                                                            "minus"
                                                        )
                                                    }
                                                >
                                                    -
                                                </button>
                                                <button
                                                    type="button"
                                                    id="minus"
                                                    name={value.uid}
                                                    className="btn btn-submit ml-1"
                                                    onClick={() =>
                                                        submitBlockRequest(
                                                            value.uid,
                                                            "vynulovat"
                                                        )
                                                    }
                                                >
                                                    0
                                                </button>
                                                <button
                                                    type="button"
                                                    id="operation"
                                                    className="btn btn-submit ml-1"
                                                    onClick={() =>
                                                        this.showOperationModal(
                                                            value.uid
                                                        )
                                                    }
                                                >
                                                    Operace
                                                </button>
                                            </td>
                                        ) : (<td>---</td>)}
                                        <td>
                                            <QRCode input={value.uid} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    {this.state.writeError ? (
                        <p className="text-danger">{this.state.writeError}</p>
                    ) : null}
                </div>
                <ClearFilters onClick={() => this.clearFilters()} />
                <Footer />
                {this.state.showOperationModalForUid && (
                    <Modal onClose={() => this.hideOperationModal()}>
                        {this.state.operations ? (
                            <div className="table-wrapper">
                                <Table>
                                    <thead>
                                        <th>Operace</th>
                                        <th>Objednávka/Zakázka</th>
                                        <th>Kusů</th>
                                        <th>Lokace</th>
                                        <th>Datum</th>
                                        <th>Uživatel</th>
                                    </thead>
                                    <tbody>
                                        {this.state.operations.map((value) => (
                                            <tr
                                                key={`${value.OPERACE}.${value.TIMESTAMP}`}
                                            >
                                                <td>{value.OPERACE}</td>
                                                <td>
                                                    {value.OPERACE ===
                                                    "VYSKLADNIT"
                                                        ? value.ZAKAZKA_LIST.join()
                                                        : value.OBJEDNAVKA}
                                                </td>
                                                <td>{value.KUSU}</td>
                                                <td>{value.LOKACE}</td>
                                                <td>
                                                    {new Date(
                                                        value.TIMESTAMP
                                                    ).toLocaleString()}
                                                </td>
                                                <td>{value.USER}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        ) : (
                            "Načítá se ze serveru..."
                        )}
                    </Modal>
                )}
            </div>
        );
    }
}
