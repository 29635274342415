import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../helpers/AuthContext";

const Header = () => {
  const {
    logout,
    username,
    userRole,
    hasAuthenticated,
  } = useContext(AuthContext);

  const handleSignOut = () => {
    logout.current();
  };

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to="/">
                Přehled
              </Link>
            </li>
            {hasAuthenticated ? (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/sortiment">
                    Sortiment
                  </Link>
                </li>
                {userRole === "Administrátor" && (
                  <li className="nav-item">
                    <Link className="nav-link" to="/sortiment_location">
                      Lokace sortimentu
                    </Link>
                  </li>
                )}
                {["Administrátor", "Programátor", "Nákupčí"].includes(
                  userRole
                ) && (
                  <li className="nav-item">
                    <Link className="nav-link" to="/orders">
                      Objednávky
                    </Link>
                  </li>
                )}
                {["Administrátor", "Nákupčí"].includes(userRole) && (
                  <li className="nav-item">
                    <Link className="nav-link" to="/contracts">
                      Zakázky
                    </Link>
                  </li>
                )}
                {userRole === "Administrátor" && (
                  <li className="nav-item">
                    <Link className="nav-link" to="/users">
                      Uživatelé
                    </Link>
                  </li>
                )}
                {userRole === "Administrátor" && (
                  <li className="nav-item">
                    <Link className="nav-link" to="/location">
                      Lokace
                    </Link>
                  </li>
                )}
                {userRole === "Administrátor" && (
                  <li className="nav-item">
                    <Link className="nav-link" to="/sortiment_type">
                      Kategorie Sortimentu
                    </Link>
                  </li>
                )}
              </>
            ) : (
              ""
            )}
          </ul>
            {hasAuthenticated ? (
              <div className="ml-auto align-items-center">
                <div className="nav-item navbar-text mr-2">
                  <span>{username}</span>
                </div>
                <button className="btn btn-primary" onClick={handleSignOut}>
                  Odhlásit se
                </button>
              </div>
            ) : (
              <div className="ml-auto">
                <Link className="btn btn-primary" to="/login">
                  Přihlásit se
                </Link>
              </div>
            )}          
      </nav>
    </header>
  );
};

export default Header;
