import React from "react";

const FulltextSearchPanel = (props) => {
	const handleOnChange = (e) => {
		props.onChange({ fulltextSearchPhrase: e.target.value.toLowerCase() });
	};

	return (
		<div className="input-group mb-3">
			<input
				type="text"
				placeholder="Napište prosím výraz, který chcete hledat"
				className="fulltext-search-panel form-control"
				value={props.value}
				onChange={handleOnChange}
			/>
		</div>
	);
};

export default FulltextSearchPanel;
