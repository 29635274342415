import React, { Component } from "react";
import { auth, db } from "../services/firebase";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Table from "react-bootstrap/Table";
import SortButton from "../components/SortButton";
import FulltextSearchPanel from "../components/FulltextSearchPanel";
import EditableColumn from "../components/EditableColumn";
import AuthContext from "../helpers/AuthContext";
import ClearFilters from "../components/ClearFilters";
import getSortedValues from "../helpers/getSortedValues";
import filterSameValues from "../helpers/filterSameValues";

export default class Orders extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            user: auth().currentUser,
            orders: [],
            readError: null,
            writeError: null,
            loading: true,
            sortBy: "TIMESTAMP",
            sortDirection: "up",
            filterByField: [],
            filterByValue: [],
            fulltextSearchPhrase: "",
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async getOrders(sortimentDoc) {
        let orders = [];
        const operationDocs = await db
            .collection("sortiment")
            .doc(sortimentDoc.id)
            .collection("operation")
            .get();
        operationDocs.forEach((operationDocTmp) => {
            const operationDoc = {
                ...operationDocTmp.data(),
                UID: operationDocTmp.id,
                SORTIMENT_UID: sortimentDoc.id,
            };
            if (
                ["NASKLADNIT", "NASKLADNIT_ZBYTEK"].includes(
                    operationDoc.OPERACE
                )
            ) {
                orders.push(operationDoc);
            }
        });
        return await Promise.all(orders);
    }

    async componentDidMount() {
        this.setState({ readError: null, loading: true });
        try {
            let orders = [];
            const sortimentDocs = await db.collection("sortiment").get();
            sortimentDocs.forEach((sortimentDoc) => {
                orders.push(this.getOrders(sortimentDoc));
            });
            const resolvedOrders = await Promise.all(orders);
            this.setState({ loading: false, orders: resolvedOrders.flat() });
        } catch (error) {
            this.setState({ readError: error.message, loading: false });
            console.log("error", error.message);
        }
    }

    handleChange(event) {
        const value = event.target.value;
        this.setState({
            [event.target.name]: value,
        });
    }

    async handleFieldChange(fieldName, sortimentUid, orderUid, value) {
        db.collection("sortiment")
            .doc(sortimentUid)
            .collection("operation")
            .doc(orderUid)
            .update({
                [fieldName]: value,
            });
        this.setState({
            orders: this.state.orders.map((order) => {
                if (order["SORTIMENT_UID"] === sortimentUid && order["UID"] === orderUid) {
                    return { ...order, [fieldName]: value };
                }
                return order;
            }),
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ writeError: null, loading: true });
        try {
            await db.collection("operation").add({
                invoices: this.state.invoices,
                contracts: this.state.contracts,
                user: this.state.user?.uid,
                created: Date.now(),
            });
            this.setState({ label: "", loading: false });
        } catch (error) {
            this.setState({ writeError: error.message, loading: false });
        }
    }

    formatTime(timestamp) {
        const d = new Date(timestamp);
        const time = `${d.getDate()}/${
            d.getMonth() + 1
        }/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
        return time;
    }

    clearFilters() {
        this.setState({
            filterByField: [],
            filterByValue: [],
        });
    }

    render() {
        const sortedValues = getSortedValues(this.state, this.state.orders);
        return (
            <div>
                <Header />
                <div>
                    {this.state.loading ? (
                        <div
                            className="spinner-border text-success"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>
                    ) : (
                        ""
                    )}

                    <FulltextSearchPanel
                        value={this.state.fulltextSearchPhrase}
                        onChange={(v) => this.setState({ ...v })}
                    />

                    <form onSubmit={this.handleSubmit} className="mx-3">
                        <div class="table-wrapper">
                            <Table>
                                <thead>
                                    <tr>
                                        <SortButton
                                            onChange={(v) =>
                                                this.setState({ ...v })
                                            }
                                            sortDirection={
                                                this.state.sortDirection
                                            }
                                            sortBy={this.state.sortBy}
                                            differentValues={filterSameValues(
                                                this.state.orders,
                                                "OBJEDNAVKA"
                                            )}
                                            filterByField={
                                                this.state.filterByField
                                            }
                                            filterByValue={
                                                this.state.filterByValue
                                            }
                                            label="OBJEDNAVKA"
                                        >
                                            Objednávka
                                        </SortButton>
                                        <SortButton
                                            onChange={(v) =>
                                                this.setState({ ...v })
                                            }
                                            sortDirection={
                                                this.state.sortDirection
                                            }
                                            sortBy={this.state.sortBy}
                                            differentValues={filterSameValues(
                                                this.state.orders,
                                                "KUSU"
                                            )}
                                            filterByField={
                                                this.state.filterByField
                                            }
                                            filterByValue={
                                                this.state.filterByValue
                                            }
                                            label="KUSU"
                                        >
                                            Počet kusů
                                        </SortButton>
                                        <SortButton
                                            onChange={(v) =>
                                                this.setState({ ...v })
                                            }
                                            sortDirection={
                                                this.state.sortDirection
                                            }
                                            sortBy={this.state.sortBy}
                                            differentValues={filterSameValues(
                                                this.state.orders,
                                                "SORTIMENT_UID"
                                            )}
                                            filterByField={
                                                this.state.filterByField
                                            }
                                            filterByValue={
                                                this.state.filterByValue
                                            }
                                            label="SORTIMENT_UID"
                                        >
                                            Sortiment Identifikátor
                                        </SortButton>
                                        <SortButton
                                            onChange={(v) =>
                                                this.setState({ ...v })
                                            }
                                            sortDirection={
                                                this.state.sortDirection
                                            }
                                            sortBy={this.state.sortBy}
                                            differentValues={filterSameValues(
                                                this.state.orders,
                                                "FAKTURA"
                                            )}
                                            filterByField={
                                                this.state.filterByField
                                            }
                                            filterByValue={
                                                this.state.filterByValue
                                            }
                                            label="FAKTURA"
                                        >
                                            Faktura
                                        </SortButton>
                                        <SortButton
                                            onChange={(v) =>
                                                this.setState({ ...v })
                                            }
                                            sortDirection={
                                                this.state.sortDirection
                                            }
                                            sortBy={this.state.sortBy}
                                            differentValues={filterSameValues(
                                                this.state.orders,
                                                "DODACI_LIST"
                                            )}
                                            filterByField={
                                                this.state.filterByField
                                            }
                                            filterByValue={
                                                this.state.filterByValue
                                            }
                                            label="DODACI_LIST"
                                        >
                                            Dodací list
                                        </SortButton>
                                        <SortButton
                                            onChange={(v) =>
                                                this.setState({ ...v })
                                            }
                                            sortDirection={
                                                this.state.sortDirection
                                            }
                                            sortBy={this.state.sortBy}
                                            label="TIMESTAMP"
                                        >
                                            Datum
                                        </SortButton>
                                        <SortButton
                                            onChange={(v) =>
                                                this.setState({ ...v })
                                            }
                                            sortDirection={
                                                this.state.sortDirection
                                            }
                                            sortBy={this.state.sortBy}
                                            label="USER"
                                        >
                                            Uživatel
                                        </SortButton>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedValues.map((order) => {
                                        return (
                                            <tr
                                                key={`${order["OBJEDNAVKA"]}.${order["TIMESTAMP"]}`}
                                            >
                                                <td>{order["OBJEDNAVKA"]}</td>
                                                <td>{order["KUSU"]}</td>
                                                <td>
                                                    {order["SORTIMENT_UID"]}
                                                </td>
                                                <td>
                                                    {[
                                                        "Administrátor",
                                                        "Nákupčí",
                                                    ].includes(
                                                        this.context.userRole
                                                    ) ? (
                                                        <EditableColumn
                                                            title="FAKTURA"
                                                            value={
                                                                order["FAKTURA"]
                                                            }
                                                            onChange={(v) =>
                                                                this.handleFieldChange(
                                                                    "FAKTURA",
                                                                    order[
                                                                        "SORTIMENT_UID"
                                                                    ],
                                                                    order[
                                                                        "UID"
                                                                    ],
                                                                    v
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        order["FAKTURA"]
                                                    )}
                                                </td>
                                                <td>
                                                    {[
                                                        "Administrátor",
                                                        "Nákupčí",
                                                    ].includes(
                                                        this.context.userRole
                                                    ) ? (
                                                        <EditableColumn
                                                            title="Dodací list"
                                                            value={
                                                                order[
                                                                    "DODACI_LIST"
                                                                ]
                                                            }
                                                            onChange={(v) =>
                                                                this.handleFieldChange(
                                                                    "DODACI_LIST",
                                                                    order[
                                                                        "SORTIMENT_UID"
                                                                    ],
                                                                    order[
                                                                        "UID"
                                                                    ],
                                                                    v
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        order["DODACI_LIST"]
                                                    )}
                                                </td>
                                                <td>
                                                    {new Date(
                                                        order["TIMESTAMP"]
                                                    ).toLocaleString()}
                                                </td>
                                                <td>{order["USER"]}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </form>

                    {this.state.writeError ? (
                        <p className="text-danger">{this.state.writeError}</p>
                    ) : null}
                </div>
                <ClearFilters onClick={() => this.clearFilters()} />
                <Footer />
            </div>
        );
    }
}
