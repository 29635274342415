import React from "react";

const EditableColumn = (props) => {
	const openPromptDialog = async () => {
		const newValue = window.prompt(
			"Upravit pole " + props.title,
			props.value
		);
		if (newValue !== null) {
			props.onChange(newValue);
		}
	};

	return (
		<div className="input-group mb-3">
			<input
				type="text"
				className="form-control editable-column-input"
				value={props.value}
				readOnly
				onClick={openPromptDialog}
			/>
		</div>
	);
};

export default EditableColumn;
