import React, { Component } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function HomePage() {
  return (
    <div className="home">
      <Header></Header>
      <section>
        <div className="jumbotron jumbotron-fluid py-5">
          <div className="container text-center py-5">
            <h1 className="display-4">Skladová evidence</h1>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
}
