import React, { Component } from "react";
import { auth, db } from "../services/firebase";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Table from "react-bootstrap/Table";
import QRCode from "../components/QRCode";

export default class Users extends Component {
	constructor(props) {
		super(props);
		this.state = {
			LOKACE: null,
			readError: null,
			writeError: null,
			locations: [],
			loading: true,
		};
	}

	async componentDidMount() {
		this.setState({ readError: null, loading: true });
		try {
			db.collection("location").onSnapshot((querySnapshot) => {
				const locations = querySnapshot.docs.map((doc) => {
					const data = doc.data();
					return { ...data, uid: doc.id };
				});
				console.log(locations);
				this.setState({
					locations,
					loading: false,
				});
			});
		} catch (error) {
			this.setState({ readError: error.message, loading: false });
		}
	}

	handleRemove(uid) {
		db.collection("location")
			.doc(uid)
			.delete();
	}

	handleChange(event) {
		const value = event.target.value;
		this.setState({
			[event.target.name]: value,
		});
	}

	async handleSubmit() {
		this.setState({ writeError: null, loading: true });
		try {
			await db.collection("location").add({
				LOKACE: `LOKACE-${this.state.LOKACE}`,
			});
			this.setState({ loading: false });
		} catch (error) {
			this.setState({ writeError: error.message, loading: false });
		}
	}

	render() {
		return (
			<div>
				<Header />
				<div>
					{this.state.loading ? (
						<div
							className="spinner-border text-success"
							role="status"
						>
							<span className="sr-only">Loading...</span>
						</div>
					) : (
						""
					)}
					<div className="table-wrapper">
						<Table>
							<thead>
								<tr>
									<th>UID</th>
									<th>Akce</th>
								</tr>
							</thead>
							<tbody>
								{this.state.locations.map(({ uid, LOKACE }) => (
									<tr key={uid}>
										<td>{LOKACE}</td>
										<td>
											<button
												type="button"
												id="remove"
												name={uid}
												className="btn btn-danger"
												onClick={() =>
													this.handleRemove(uid)
												}
											>
												X
											</button>
										</td>
										<td><QRCode input={LOKACE}/></td>
									</tr>
								))}
								<tr>
									<td>
										<div className="input-group mb-3">
											LOKACE-
											<input
												type="text"
												name="LOKACE"
												className="form-control"
												placeholder="Zadejte UID lokace"
												onChange={(e) =>
													this.handleChange(e)
												}
											/>
										</div>
									</td>
									<td>
										<button
											onClick={() => this.handleSubmit()}
											className="btn btn-submit px-5"
										>
											Přidat
										</button>
									</td>
								</tr>
							</tbody>
						</Table>
					</div>
					{this.state.writeError ? (
						<p className="text-danger">{this.state.writeError}</p>
					) : null}
				</div>

				<Footer />
			</div>
		);
	}
}
