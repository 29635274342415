export function getFilteredValues(state, values) {
    const fulltextValues =
        state.fulltextSearchPhrase.length > 0
            ? values.filter((row) => {
                  return Object.values(row).some((v) =>
                      (v || "")
                          .toString()
                          .toLowerCase()
                          .includes(state.fulltextSearchPhrase)
                  );
              })
            : values;
    const filterByColumn = (data, columnFilterField, columnFilterValue) =>
        data.filter((v) => {
            return [].concat(columnFilterValue).some((oneFilterValue) => {
                return (
                    (v[columnFilterField] || "").toString() ===
                    oneFilterValue.toString()
                );
            });
        });
    const filteredValues =
        state.filterByField.length > 0 && state.filterByValue.length === state.filterByField.length
            ? state.filterByField.reduce(
                  (values, filterField, filterIndex) =>
                      filterByColumn(
                          values,
                          filterField,
                          state.filterByValue[filterIndex]
                      ),
                  fulltextValues
              )
            : fulltextValues;
    return filteredValues;
}

export default function getSortedValues(state, values) {
    const filteredValues = getFilteredValues(state, values);
    if (!state.sortBy) {
        return filteredValues;
    }
    return filteredValues.sort((a, b) => {
        a = a[state.sortBy] ?? null;
        b = b[state.sortBy] ?? null;
        let r =
            a === null && b !== null ? -1 : a !== null && b === null ? 1 : 0;
        if (typeof a === "string" && typeof b === "string") {
            r = a.localeCompare(b);
        } else if (typeof a === "number" && typeof b === "number") {
            r = a - b;
        }
        return state.sortDirection === "up" ? r * -1 : r;
    });
}
